import { Model } from "hybrids";
import User from "./User";

interface Sponsor {
  id: string;
  name: string;
  image: string;
  profile: User;
}

const Sponsor: Model<Sponsor> = {
  id: true,
  name: "",
  image: "",
  profile: User,
};

export default Sponsor;
