import { ModelIdentifier, Storage } from "hybrids";

const API_URL = "/client_side_api";
const HYBRIDS_CLIENT_TOKEN = "0d7c2880-d9e7-4888-b2f2-695d27eda9c5";

const options: RequestInit = {
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Client-Token": HYBRIDS_CLIENT_TOKEN,
  },
  credentials: "include",
};

function stringify(data?: object) {
  return JSON.stringify(data, function replacer(key, value) {
    if (value === this[""]) return value;

    if (
      value &&
      typeof value === "object" &&
      !Array.isArray(value) &&
      value.hasOwnProperty("id")
    ) {
      return value.id;
    }

    return value;
  });
}

function url(path: string, id?: ModelIdentifier) {
  const url = new URL(
    `${API_URL}${path
      .replace(":id", typeof id === "string" ? id : String(id?.id))
      .replace(/\/$/, "")}`,
    window.location.origin
  );

  if (id) {
    if (typeof id === "object") {
      Object.entries(id).forEach(([key, value]) => {
        if (key === "id" && path.includes(":id")) return;
        url.searchParams.append(key, value as string);
      });
    } else if (!path.includes(":id")) {
      url.searchParams.append("id", id as string);
    }
  }

  return String(url);
}

interface ApiError extends Error {
  msg?: string;
  errors?: object;
  terminateUrl?: string;
}

async function resolve(res: Response) {
  if (res.status >= 400) {
    let error;

    try {
      error = await res.json();
    } catch (e) {
    }

    if (error && typeof error === "object") {
      if (error.message) throw Error(error.message);

      if (error.hasOwnProperty("msg")) {
        const e: ApiError = new Error(error.msg);
        if (Object.keys(error.errors).length) e.errors = error.errors;
        if (error.terminateUrl) e.terminateUrl = error.terminateUrl;

        throw e;
      }
    }

    throw Error(res.statusText);
  }

  return await res.json().catch(() => undefined);
}

export async function get(path: string, id?: ModelIdentifier) {
  const res = await fetch(url(path, id), options);
  return resolve(res);
}

export async function post(
  path: string,
  values?: Object | null,
  id?: ModelIdentifier
) {
  const res = await fetch(url(path, id), {
    ...options,
    method: "post",
    body: values ? stringify(values) : undefined,
  });

  return resolve(res);
}

export async function put(path: string, id: ModelIdentifier, values: Object) {
  const res = await fetch(url(path, id), {
    ...options,
    method: "put",
    body: stringify(values),
  });

  return resolve(res);
}

export async function patch(
  path: string,
  id: ModelIdentifier,
  values?: Object
) {
  const res = await fetch(url(path, id), {
    ...options,
    method: "patch",
    body: stringify(values),
  });

  return resolve(res);
}

export async function remove(path: string, id: ModelIdentifier) {
  const res = await fetch(url(path, id), {
    ...options,
    method: "delete",
  });

  return resolve(res).then(() => null);
}

export default function api<M>(
  path: string,
  enumerable: boolean = true
): Storage<M> {
  let storage: Storage<M> = {
    get: (id) => get(path, id),
    set(id, values) {
      if (values === null) {
        return remove(path, id);
      }

      return enumerable && id ? put(path, id, values) : post(path, values);
    },
  };

  if (enumerable) {
    storage.list = function list(id) {
      return get(path, id);
    };
  }

  return storage;
}
