import { html } from "hybrids";

export default function layout(
  type: "grid" | "flex" | "block" | "inline-block" | "inline"
) {
  let css = "";

  switch (type) {
    case "flex":
      css = `
        :host {
          display: flex;
          flex-flow: column nowrap;
          flex: 1 1 auto;
          min-width: 0;
          min-height: 0;
        };
      `;
      break;
    case "grid":
      css = `
        :host {
          display: grid;
          grid: 1fr / auto;
        }
      `;
      break;
    default:
      css = `:host { display: ${type}; }`;
  }

  const template = html`<slot></slot>`.style(
    css,
    `:host([hidden]) { display: none }`
  );

  return () => template;
}
