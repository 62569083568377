import { Model, store } from "hybrids";
import { format } from "date-fns";
import Broadcast from "./Broadcast";
import ArtistVideo from "./ArtistVideo";

import User from "./User";
import { post } from "~/utils/api";

interface Comment {
  id: string;
  content: string;
  createdAt: string;
  likes: number;
  user: User;
  firstName: string;
  lastName: string;
  parent?: Comment;
  context: {
    identifier: string;
    type: string;
  };

  time: string;
}

const Comment: Model<Comment> = {
  id: true,
  content: store.value("", /.+/, "Content can't be blank"),
  createdAt: "",
  likes: 0,
  user: User,
  firstName: "",
  lastName: "",
  parent: store.ref(() => Comment),
  // Default context type is "broadcast"
  context: { identifier: "", type: "broadcast" },
  [store.connect]: {
    set(id, values) {
      if (values) {
        if (!id) {
          switch (values.context.type) {
            case "broadcast":
              return post(
                `/broadcasts/:id/comments`,
                values,
                values.context.identifier
              );
            case "video":
              return post(
                `/artist_videos/:id/comments`,
                values,
                values.context.identifier
              );
            default:
              throw new Error("Invalid context type");
          }
        }
      }

      return values;
    },
  },

  time: ({ createdAt }) => format(new Date(createdAt), "HH:mm"),
};

export default Comment;
