import { store, Model } from "hybrids";
import { get } from "~/utils/api";

interface User {
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  username: string;
  avatar: string;
  avatarThumbnail: string;
  member: boolean;
  artist: boolean;
  registered: boolean;
  type: string;

  // Session based user
  email: string;
  admin: boolean;

  // computed
  pathname: string;
}

const User: Model<User> = {
  id: true,
  name: "",
  firstName: "",
  lastName: "",
  username: "",
  avatar: "",
  avatarThumbnail: "",
  member: false,
  artist: false,
  registered: true,
  type: "",

  // Session based user
  email: "",
  admin: false,

  pathname: ({ type, username }) =>
    `/${(type === "User" && "p") || (type === "School" && "o")}/${username}`,

  [store.connect]: {
    list(query) {
      if (typeof query === "object" && query.filter === "artists") {
          if (query.tag) {
            return get("/artists/tagged", {
              tag: query.tag,
              page: query.page,
            });
          }

        return get("/artists/featured");
      }
      throw Error(`Not supported query: ${query}`);
    },
    offline: 1000 * 60 * 60 * 24 * 7, // 7 days
  },
};

export default User;
