import { Model } from "hybrids";
import User from "./User";

interface Payment {
  id: string;
  user: User;
  amount: number;
}

const Payment: Model<Payment> = {
  id: true,
  user: User,
  amount: 0,
};

export default Payment;
